import React from "react";
import { FiCheck, FiX, FiInfo } from "react-icons/fi";
// import { FiCross } from "react-icons/fi";

const PricingFive = () => {
  return (
    <div className="row row--15">
      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-5">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">Unlit</h4>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">£</span>
                  <span className="price">200</span>
                </div>
                <span className="subtitle">Prices from</span>
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li style={{ color: "white" }}>
                  <FiCheck /> Modular
                </li>
                <li style={{ color: "white" }}>
                  <FiCheck /> Printed Fabric Front
                </li>
                <li style={{ color: "white" }}>
                  <FiCheck /> Printed Fabric Back
                </li>
                <li style={{ color: "white" }}>
                  <FiInfo /> Max Size 5m x 10m
                </li>
                <li>
                  <FiX /> Internal lighting
                </li>
                <li>&nbsp;</li>
              </ul>
            </div>
            <div className="pricing-footer">
              <a
                className="btn-default btn-border"
                href="src/doob/elements/pricing/PricingFive#pricing"
              >
                Purchase Now
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-5">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">Edgelit</h4>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">£</span>
                  <span className="price">300</span>
                </div>
                <span className="subtitle">Prices from</span>
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li style={{ color: "white" }}>
                  <FiCheck /> Modular
                </li>
                <li style={{ color: "white" }}>
                  <FiCheck /> Printed Fabric Front
                </li>
                <li style={{ color: "white" }}>
                  <FiCheck /> Printed Fabric Back
                </li>
                <li style={{ color: "white" }}>
                  <FiInfo /> Max Size 3m x 10m
                </li>
                <li style={{ color: "white" }}>
                  <FiCheck /> Internal lighting
                </li>
                <li>
                  <FiX /> Animated Lights
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <a
                className="btn-default"
                href="src/doob/elements/pricing/PricingFive#pricing"
              >
                Purchase Now
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}

      {/* Start PRicing Table Area  */}
      <div className="col-lg-4 col-md-6 col-12">
        <div className="rn-pricing style-5">
          <div className="pricing-table-inner">
            <div className="pricing-header">
              <h4 className="title">Backlit</h4>
              <div className="pricing">
                <div className="price-wrapper">
                  <span className="currency">£</span>
                  <span className="price">500</span>
                </div>
                <span className="subtitle">Prices from</span>
              </div>
            </div>
            <div className="pricing-body">
              <ul className="list-style--1">
                <li style={{ color: "white" }}>
                  <FiCheck /> Modular
                </li>
                <li style={{ color: "white" }}>
                  <FiCheck /> Printed Fabric Front
                </li>
                <li>
                  <FiX /> Printed Fabric Back
                </li>
                <li style={{ color: "white" }}>
                  <FiInfo /> Max Size 5m x 10m
                </li>
                <li style={{ color: "white" }}>
                  <FiCheck /> Internal lighting
                </li>
                <li>
                  <FiX /> Animated Lights
                </li>
              </ul>
            </div>
            <div className="pricing-footer">
              <a
                className="btn-default btn-border"
                href="src/doob/elements/pricing/PricingFive#pricing"
              >
                Purchase Now
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* End PRicing Table Area  */}
    </div>
  );
};
export default PricingFive;

import BlogClassicData from "../../doob/data/blog/BlogList.json";
import SEO from "../../common/SEO";
import HeaderTopNews from "../../doob/common/header/HeaderTopNews";
import Header from "../../common/header/Header";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "gatsby";
import Separator from "../../components/Separator";
import SectionTitle from "../../components/SectionTitle";
import FooterOne from "../../doob/common/footer/FooterOne";
import Copyright from "../../doob/common/footer/Copyright";
import React from "react";
import PricingOne from "../../doob/elements/pricing/PricingOne";
import PricingTwo from "../../doob/elements/pricing/PricingTwo";
import PricingThree from "../../doob/elements/pricing/PricingThree";
import PricingFour from "../../doob/elements/pricing/PricingFour";
import PricingFive from "../../doob/elements/pricing/PricingFive";
import PricingFiveCopy from "../../doob/elements/pricing/PricingFiveCopy";
import PricingFiveCopyTwo from "../../doob/elements/pricing/PricingFiveCopyTwo";

const HomeDefault = () => {
  return (
    <>
      <SEO title="Business Consulting" />
      <main className="page-wrapper">
        <HeaderTopNews />
        <Header transparent />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 variation-default height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(assets/images/bg/bg-image-3.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">DIGITAL CONSULTING AGENCY</span>
                  <h1 className="title display-one">
                    Unique Business <span>Consulting</span>.
                  </h1>
                  <p className="description">
                    We help our clients succeed by creating brand identities,
                    digital experiences, and print materials.
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                    >
                      Purchase Now{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="#"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Pricing"
                  title="Pricing Style One."
                  description=""
                />
              </div>
            </div>
            <PricingOne />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Pricing"
                  title="Pricing Style Two."
                  description=""
                />
              </div>
            </div>
            <PricingTwo />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Pricing"
                  title="Pricing Style Three."
                  description=""
                />
              </div>
            </div>
            <PricingThree />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="wrapper plr--100 plr_md--15 plr_sm--15">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Pricing"
                  title="Pricing Style Four."
                  description=""
                />
              </div>
            </div>
            <PricingFour />
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="Pricing"
                  title="Lightboxes"
                  description="See our range of LED lightboxes"
                />
              </div>
            </div>
            <PricingFive />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="Pricing"
                  title="Standard Lightboxes"
                  description="See our range of LED lightboxes"
                />
              </div>
            </div>
            <PricingFiveCopy />
          </div>
        </div>
        {/* End Elements Area  */}
        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  // subtitle="Pricing"
                  title="HueBox Lightboxes"
                  description="The ultimate lightbox"
                />
              </div>
            </div>
            <PricingFiveCopyTwo />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
